<div class="p2p-setup tw-flex tw-justify-center tw-items-center tw-flex-col tw-relative tw-w-full tw-h-full tw-rounded-2xl tw-p-8">
  <div class="tw-flex tw-flex-col tw-gap-4 tw-w-full">
    <div class="p2p-setup__header tw-font-medium tw-relative">
      <div class="tw-text-center tw-w-full">{{ 'P2P_SETTINGS.SETUP_TITLE' | translate }}</div>
    </div>
    <div class="p2p-setup__header__describe tw-text-base tw-font-normal tw-text-center">
      {{ 'P2P_SETTINGS.ADD_TRADE_LINK_AND_API_KEY' | translate }}
    </div>
  </div>
  <!-- trade link input -->

  <ng-container *ngTemplateOutlet="p2pInputTemplate; context: { config: TradeLinkTemplateContext, status: tradeLinkFormStatus() }" />

  @if (isEmailNotConfirmed()) {
    <div class="horizontal-line"></div>
    <!-- email input -->
    <div class="tw-flex tw-flex-col tw-w-full">
      <div class="p2p-setup__header tw-font-medium tw-text-center tw-pb-4">
        {{ 'P2P_SETTINGS.EMAIL_TITLE' | translate }}
      </div>
      <div class="p2p-setup__header__describe tw-text-base tw-font-normal tw-text-center tw-pb-4">
        {{ 'P2P_SETTINGS.ADD_EMAIL' | translate }}
      </div>
      <div class="tw-flex tw-gap-3 tw-pb-3">
        <ng-container *ngTemplateOutlet="p2pInputTemplate; context: { config: MailTemplateContext }" />
        <ng-container *ngTemplateOutlet="p2pInputTemplate; context: { config: CodeTemplateContext, status: confirmCodeFormStatus() }" />
      </div>
      <div class="p2p-setup__header__describe tw-text-base tw-font-normal tw-text-center tw-pb-6">
        {{ 'P2P_SETTINGS.EMAIL.CHECK_EMAIL_CODE' | translate }}
      </div>
      <button
        class="btn !tw-rounded-xl"
        [ngClass]="{
          'btn-gray--design':
            p2pSettingsForm.controls.emailcode.invalid || p2pSettingsForm.controls.email.invalid || !isEmailNotConfirmed(),
          'btn-primary--design': p2pSettingsForm.controls.emailcode.valid && isEmailNotConfirmed()
        }"
        (click)="confirmEmail()"
      >
        {{ 'P2P_SETTINGS.EMAIL.CONFIRM_EMAIL' | translate }}
      </button>
    </div>
  }

  <ng-template
    #p2pInputTemplate
    let-config="config"
    let-status="status"
  >
    <div
      class="setting-item tw-font-normal tw-flex tw-flex-col tw-gap-2 tw-w-full"
      [formGroup]="p2pSettingsForm"
    >
      <div
        class="tw-w-full tw-text-end tw-text-red-500 tw-text-xs tw-opacity-0"
        [class.visible]="status === StateActionStatus.ERROR"
      >
        {{ config.formErrorLocale | translate }}
      </div>

      <app-ui-input
        [class.success]="status === StateActionStatus.SUCCESS"
        [placeholder]="config.formPlaceholder | translate"
        [formControlName]="config.formControlName"
        [canPaste]="true"
      >
        <ng-container *ngTemplateOutlet="config.inputInnerTemplate === 'linkTemplate' ? linkTemplate : confirmTemplate" />
      </app-ui-input>
    </div>
    <ng-template #linkTemplate>
      @if (status === StateActionStatus.DISPATCH) {
        <app-ui-spinner-loader class="tw-pr-3 tw-text-blue-500 tw-w-8 tw-h-8 tw-items-center" />
      } @else {
        <a
          class="tw-text-sm tw-font-normal tw-text-blue-500 !tw-no-underline tw-pr-3"
          [href]="config.whereLink"
          target="_blank"
        >
          {{ config.whereLinkLocale | translate }}
        </a>
      }
    </ng-template>
    <ng-template #confirmTemplate>
      @if (config.inputInnerTemplate) {
        <div
          class="confirm-btn tw-w-8 tw-h-8 tw-rounded-full tw-cursor-pointer tw-mr-3 tw-flex tw-items-center tw-justify-center"
          [class.valid]="p2pSettingsForm.controls.email.valid"
          (click)="submitEmail()"
        >
          <mat-icon svgIcon="arrow-right-line"></mat-icon>
        </div>
      }
    </ng-template>
  </ng-template>
</div>

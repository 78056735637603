import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { provideAnimations } from '@angular/platform-browser/animations';
import type { IP2pDepositingItemV2 } from '@dev-fast/types';
import { OrderStatusEnum } from '@dev-fast/types';
import { map } from 'rxjs';

import { expansionAnimation } from '@app/games/lib/shared/animations';
import { SihService } from '@app/widgets/sih';

import { CreatingTradePanelComponent } from './components/creating-trade-panel/creating-trade-panel.component';
import { ProceedWithTradeComponent } from './components/proceed-with-trade/proceed-with-trade.component';
import { SIHCheckPanelComponent } from './components/sih-check-panel/sih-check-panel.component';
import { SIHConfirmPanelComponent } from './components/sih-confirm-panel/sih-confirm-panel.component';

@Component({
  standalone: true,
  selector: 'app-p2p-sih-auto-trade',
  templateUrl: './p2p-sih-auto-trade.component.html',
  styleUrl: './shared-styles.scss',
  animations: [expansionAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SihService, provideAnimations()],
  imports: [MatIconModule, SIHCheckPanelComponent, SIHConfirmPanelComponent, ProceedWithTradeComponent, CreatingTradePanelComponent],
})
export class P2pSIHAutoTradeComponent {
  readonly #sihService = inject(SihService);

  tradeOffer = input<IP2pDepositingItemV2>();
  isMobileView = input<boolean>();
  closeModal = output();
  createTradeManually = output();

  readonly loading = toSignal<boolean>(this.#sihService.checkUserLoading$);
  readonly isFirstStep = toSignal<boolean>(this.#sihService.sih$.pipe(map((sih) => (sih ? !sih.online : true))));
  readonly isSihDisabled = toSignal<boolean>(this.#sihService.showSihWarn$);

  readonly orderSuccessful = [OrderStatusEnum.AUCTION_FINISHED, OrderStatusEnum.COMPLETED, OrderStatusEnum.WAIT_FOR_TRADE_CONFIRM];
}

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { IP2pSettingsForm, IP2pSettingsInputTemplateContext } from '@dev-fast/types';
import {
  EMAIL_REG_EX,
  EmailStatus,
  ModalNames,
  SocialType,
  StateActionStatus,
  SteamTradeStatus,
  TRADE_LINK_REG_EXP,
} from '@dev-fast/types';
import type { Observable } from 'rxjs';
import { debounceTime, merge, mergeWith, tap } from 'rxjs';

import { AuthService } from '@app/auth';
import { ModalsService } from '@app/core/modal-service';
import { UserService } from '@app/core/user-service';

//ОТказались от логики апи ключей еще весной, судя по всему deprecated компонент
@Component({
  selector: 'app-p2p-setup-modal',
  templateUrl: './p2p-setup-modal.component.html',
  styleUrls: ['./p2p-setup-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pNewSetupModalComponent implements OnInit {
  steamStatus = input(SteamTradeStatus.NEED_PERMISSIONS_ADD);

  readonly #destroyRef = inject(DestroyRef);
  readonly #userService = inject(UserService);
  readonly #authService = inject(AuthService);
  readonly #modalsService = inject(ModalsService);
  readonly #data = inject<SteamTradeStatus | undefined>(MAT_DIALOG_DATA, { optional: true });

  readonly steamStatusOrData = computed(() => this.#data ?? this.steamStatus());

  tradeLinkFormStatus = signal(StateActionStatus.DEFAULT);
  apiKeyFormStatus = signal(StateActionStatus.DEFAULT);
  confirmCodeFormStatus = signal(StateActionStatus.DEFAULT);
  isEmailNotConfirmed = signal(false);
  #isUserSubscribed = signal(false);

  // Forms
  readonly p2pSettingsForm = new FormGroup<IP2pSettingsForm>({
    tradelink: new FormControl('', [Validators.pattern(TRADE_LINK_REG_EXP), Validators.required]),
    email: new FormControl('', [Validators.email, Validators.pattern(EMAIL_REG_EX), Validators.required]),
    emailcode: new FormControl('', Validators.required),
  });

  readonly steamGuardForm = new FormGroup({
    steamGuard: new FormControl(''),
    status: new FormControl(StateActionStatus.DEFAULT, [Validators.pattern(StateActionStatus.SUCCESS)]),
  });

  // Consts
  readonly StateActionStatus = StateActionStatus;
  readonly SteamTradeStatusEnum: typeof SteamTradeStatus = SteamTradeStatus;
  readonly SocialTypeEnum: typeof SocialType = SocialType;
  //теперь можно в консты вынести
  readonly TradeLinkTemplateContext: IP2pSettingsInputTemplateContext = {
    formControlName: 'tradelink',
    inputInnerTemplate: 'linkTemplate',
    formErrorLocale: 'PROFILE.RENEW_PROFILE.TRADE.INVALID_LINK',
    formPlaceholder: 'P2P_SETTINGS.TRADE_LINK.INSERT_TRADE_LINK',
    whereLink: '//steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url',
    whereLinkLocale: 'P2P_SETTINGS.TRADE_LINK.GET_TRADE_LINK',
  };

  readonly MailTemplateContext: IP2pSettingsInputTemplateContext = {
    formControlName: 'email',
    inputInnerTemplate: 'confirmTemplate',
    formPlaceholder: 'P2P_SETTINGS.EMAIL.INSERT_EMAIL',
    formErrorLocale: 'P2P_SETTINGS.EMAIL.INVALID_EMAIL',
  };

  readonly CodeTemplateContext: IP2pSettingsInputTemplateContext = {
    formControlName: 'emailcode',
    formPlaceholder: 'P2P_SETTINGS.EMAIL.INSERT_EMAIL_CODE',
    formErrorLocale: 'P2P_SETTINGS.EMAIL.INVALID_CODE',
  };

  ngOnInit(): void {
    merge(
      this.#tradelink,
      this.#tradeLinkLoadStatus,
      this.#tradelinkValueChanged,

      this.#userEmailStatus,
      this.#confirmEmailStatus,
    )
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  get #tradelink(): Observable<string | undefined> {
    return this.#userService.tradeLink$.pipe(
      tap((link) => {
        this.p2pSettingsForm.controls.tradelink.patchValue(link || '', { emitEvent: false });
      }),
    );
  }

  get #userEmailStatus(): Observable<{ status: EmailStatus; address: string; isSubscribed: boolean } | undefined> {
    return this.#userService.userEmail$.pipe(
      tap((userEmail) => {
        this.p2pSettingsForm.controls.email.patchValue(userEmail?.address || '', { emitEvent: false });
        this.#isUserSubscribed.set(userEmail?.isSubscribed || false);
        this.isEmailNotConfirmed.set(userEmail?.status !== EmailStatus.CONFIRMED || false);
      }),
    );
  }

  get #confirmEmailStatus(): Observable<StateActionStatus> {
    return this.#userService.confirmEmailLoadStatus$.pipe(
      tap((status) => {
        this.confirmCodeFormStatus.set(status);
      }),
    );
  }

  get #tradeLinkLoadStatus(): Observable<StateActionStatus> {
    return this.#userService.tradeLinkLoadStatus$.pipe(
      mergeWith(this.#userService.tradeLinkValidation$),
      tap((formStatus) => {
        this.tradeLinkFormStatus.set(formStatus);
        this.p2pSettingsForm.controls.tradelink.markAsPristine();
        if (formStatus === StateActionStatus.ERROR) {
          this.#setFormFieldError(this.p2pSettingsForm.controls.tradelink);
        }
      }),
    );
  }

  get #tradelinkValueChanged(): Observable<any> {
    return this.p2pSettingsForm.controls.tradelink.valueChanges.pipe(
      debounceTime(400),
      tap(() => {
        this.#onSubmitTradeLink();
      }),
    );
  }

  authorize(provider: SocialType): void {
    this.#authService.onLogin(provider);
  }

  closeP2pSetupModal(): void {
    this.#modalsService.closeModal(ModalNames.P2P_SETUP);
  }

  submitEmail(): void {
    const email = this.p2pSettingsForm.controls.email.value;
    if (email && this.p2pSettingsForm.controls.email.valid && this.isEmailNotConfirmed()) {
      this.#userService.updateEmail({
        address: email,
        isSubscribed: this.#isUserSubscribed(),
      });
    }
  }

  confirmEmail(): void {
    const code = this.p2pSettingsForm.controls.emailcode.value;
    if (code && this.p2pSettingsForm.controls.emailcode.valid && this.isEmailNotConfirmed()) {
      this.#userService.confirmEmail(+code.replace(/\s|-/g, ''));
    }
  }

  #onSubmitTradeLink(): void {
    const steamLink = this.p2pSettingsForm.controls.tradelink.value;
    if (steamLink && this.p2pSettingsForm.controls.tradelink.valid) {
      this.#userService.updateTradeLink({ steam: steamLink });
    }
  }

  #setFormFieldError(field: FormControl<string | null>): void {
    field.markAsTouched();
    field.markAsDirty();
    field.setErrors({ incorrect: true });
  }
}

import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import type { IP2pDepositingItemV2 } from '@dev-fast/types';
import { DateFormat } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { combineLatest, interval, timer } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { SihService } from '@app/widgets/sih';

import { P2pManualTradeComponent } from './components/p2p-manual-trade/p2p-manual-trade.component';
import { P2pSIHAutoTradeComponent } from './components/p2p-sih-auto-trade/p2p-sih-auto-trade.component';
import { TradeCompleteService } from './p2p-trade-complete.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-p2p-new-auction-completed-modal',
  templateUrl: './p2p-auction-completed-modal.component.html',
  styleUrl: './p2p-auction-completed-modal.component.scss',
  providers: [SihService],
  imports: [DatePipe, TranslateModule, MatTabsModule, P2pManualTradeComponent, P2pSIHAutoTradeComponent, NgScrollbarModule],
})
export class P2pNewAuctionCompletedModalComponent {
  readonly #dialogRef = inject(MatDialogRef<P2pNewAuctionCompletedModalComponent>);
  readonly #service = inject(TradeCompleteService);
  readonly #sihService = inject(SihService);

  readonly depositItemId: number = inject(MAT_DIALOG_DATA);
  //template
  activeTabIndex: 0 | 1 = 0;
  readonly dateFormat = signal<DateFormat>(DateFormat.MINUTES);

  readonly isMobileView = toSignal<boolean>(this.#service.isMobile$);
  readonly isFirstStep = toSignal<boolean>(this.#sihService.sih$.pipe(map((sih) => (sih ? !sih.online : true))));
  readonly isSihDisabled = toSignal<boolean>(this.#sihService.showSihWarn$);

  readonly depositingItem$ = this.#service.depositing$.pipe(
    filter((items) => items.some((el) => el.id === this.depositItemId)),
    map((items) => items.find((el) => el.id === this.depositItemId) as IP2pDepositingItemV2),
    filter((item): item is IP2pDepositingItemV2 => item !== undefined),
  );

  readonly depositingItem = toSignal(
    combineLatest([this.depositingItem$]).pipe(map(([depositingItem]) => depositingItem as IP2pDepositingItemV2)),
  );

  readonly offerTimer = toSignal(
    combineLatest([this.depositingItem$]).pipe(
      map(([depositingItem]) => depositingItem as IP2pDepositingItemV2),
      filter(({ nextStatusAt }) => DateTime.fromISO(nextStatusAt).toLocal().toMillis() > DateTime.local().toMillis()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(DateTime.fromISO(nextStatusAt).toLocal().toMillis() - DateTime.local().toMillis()),
          map(() => DateTime.fromISO(nextStatusAt).toLocal().toMillis() - DateTime.local().toMillis()),
          tap((val) => this.dateFormat.set(this.#getDateFormat(val))),
          takeUntil(timer(DateTime.fromISO(nextStatusAt).toLocal().toMillis() - DateTime.local().toMillis())),
        ),
      ),
    ),
  );

  readonly SIHTradeInProgress = computed<boolean>(
    () => !!(this.activeTabIndex && this.depositingItem()?.order.senderAutomatedTrading && !this.isFirstStep() && !this.isSihDisabled()),
  );

  constructor() {
    effect(() => {
      const item = this.depositingItem();
      if (!item) {
        this.activeTabIndex = 0;
        return;
      }
      this.activeTabIndex = item.order.senderAutomatedTrading ? 1 : 0;
    });
  }

  onClose(): void {
    this.#dialogRef.close();
  }

  #getDateFormat(data: number): DateFormat {
    const TIME_IN_MS = 3600000; // 60min in ms
    return data > TIME_IN_MS ? DateFormat.DEFAULT : DateFormat.MINUTES;
  }
}

import { Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import type { IUserP2pPermissionInterface } from '@dev-fast/types';
import { ModalNames, SocialType, StateActionStatus, SteamErrorsEnum, SteamTradeStatus } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { AuthService } from '@app/auth';
import { ModalsService } from '@app/core/modal-service';
import { UserService } from '@app/core/user-service';

import { P2pSetupModalModule } from '../p2p-setup-modal/p2p-setup-modal.module';
import { dayWordConstructor } from './p2p-errors-description.helpers';

interface P2pErrorsDescriptionDialogData {
  status: SteamTradeStatus;
  permissions?: IUserP2pPermissionInterface;
}

@Component({
  selector: 'app-p2p-errors-description',
  standalone: true,
  templateUrl: 'p2p-errors-description.component.html',
  styleUrl: 'p2p-errors-description.component.scss',
  imports: [MatIconModule, TranslateModule, P2pSetupModalModule],
})
export class P2pErrorsDescriptionComponent {
  steamStatus = input(SteamTradeStatus.NEED_PERMISSIONS_ADD);

  readonly #userService = inject(UserService);
  readonly #authService = inject(AuthService);
  readonly #modalsService = inject(ModalsService);
  readonly #data = inject<P2pErrorsDescriptionDialogData | undefined>(MAT_DIALOG_DATA, { optional: true });

  readonly userP2pPermissionsOriginal = toSignal(this.#userService.p2pPermissions$);

  // Для debug-menu
  readonly steamStatusOrData = computed(() => this.#data?.status ?? this.steamStatus());
  readonly userP2pPermissions = computed(() => this.#data?.permissions ?? this.userP2pPermissionsOriginal());

  readonly StateActionStatus = StateActionStatus;
  readonly SteamTradeStatusEnum = SteamTradeStatus;
  readonly SocialTypeEnum = SocialType;
  readonly SteamErrors = SteamErrorsEnum;

  dayWordClassConstructor = dayWordConstructor;

  authorize(provider: SocialType): void {
    this.#authService.onLogin(provider);
  }

  closeModal(): void {
    this.#modalsService.closeModal(ModalNames.P2P_ERRORS_DESCRIPTION);
  }

  redirectToDownload(link: string): void {
    window.open(link, '_blank');
  }
}

<div
  class="tw-bg-[var(--color-gray-600)] tw-flex tw-justify-center tw-items-center tw-flex-col tw-relative tw-w-full tw-h-full tw-rounded-2xl tw-p-8"
>
  @switch (steamStatusOrData()) {
    @case (SteamTradeStatusEnum.NEED_STEAM_LOGIN) {
      <div class="tw-flex tw-flex-col tw-gap-4">
        <div class="tw-text-[var(--color-gray-100)] tw-font-medium tw-w-full tw-text-center">
          {{ 'LOCAL.PAYMENT.REFILL.AUTORIZATION_REQUIRES.MESSAGE' | translate }}
        </div>
        <button
          class="btn btn-semi-round md btn-inversion-yellow--design"
          (click)="authorize(SocialTypeEnum.Steam)"
        >
          <mat-icon svgIcon="steam" />
          {{ 'LOCAL.PAYMENT.REFILL.AUTORIZATION_REQUIRES.BUTTON' | translate }}
        </button>
      </div>
    }

    @case (SteamTradeStatusEnum.CANT_TRADE) {
      <div class="tw-flex tw-flex-col tw-gap-4">
        <mat-icon
          class="steam-icon tw-absolute tw-top-1/2 tw-left-1/2 !tw-w-auto"
          svgIcon="steam-logo-2"
        />
        <mat-icon
          class="block-icon !tw-w-full !tw-max-w-20 !tw-h-auto"
          svgIcon="handcuffs"
        />
      </div>
      <div class="p2p-setup__header tw-font-medium tw-w-full tw-text-center">
        {{ 'LOCAL.PAYMENT.REFILL.STEAM_SETTINGS.BAN_TITLE' | translate }}
      </div>
      <div class="p2p-setup__header__describe tw-text-base tw-font-normal tw-text-center">
        {{ 'LOCAL.PAYMENT.REFILL.STEAM_SETTINGS.BAN_DESCRIPTION' | translate }}
      </div>
    }

    @case (SteamTradeStatusEnum.NEED_PERMISSIONS_ADD) {
      @if (userP2pPermissions(); as userP2pPermissions) {
        @if (
          userP2pPermissions.canTrade?.error === SteamErrors.STEAM_GUARD_HOLD ||
          userP2pPermissions.canTrade?.error === SteamErrors.STEAM_GUARD_IS_IN_HOLD
        ) {
          <div class="tw-flex tw-flex-col tw-gap-10">
            <div class="tw-grid tw-justify-center tw-relative tw-items-center">
              <img
                class="tw-max-h-full tw-max-w-none tw-w-[9.5rem] tw-m-auto"
                src="/assets/img/p2p/steam-white-gradient.svg"
                alt=""
              />

              <div class="tw-flex tw-gap-4 tw-items-end tw-mt-[-2rem] tw-justify-self-end">
                <mat-icon
                  svgIcon="locked"
                  class="locked-icon tw-self-end"
                >
                </mat-icon>
                @if (userP2pPermissions.canTrade?.days; as days) {
                  <span class="text-guard-hold-time tw-text-yellow-500">{{ days }} {{ dayWordClassConstructor(days) | translate }}</span>
                }
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-gap-3">
              <span class="title-description tw-text-gray-150 tw-text-center">
                {{ 'PROFILE.RENEW_PROFILE.TRADE.STEAM_GUARD_IS_IN_HOLD' | translate }}
              </span>
              <span class="description tw-text-gray-400 tw-text-center"
                >{{ 'P2P_WIDGETS.PURCHASE.STEAM_GUARD' | translate }}
                <a class="tw-text-blue-500 tw-cursor-pointer tw-underline-none">{{
                  'P2P_WIDGETS.PURCHASE.STEAM_GUARD_LINK' | translate
                }}</a>
              </span>
            </div>
          </div>
        }
        @if (userP2pPermissions.canTrade?.error === SteamErrors.STEAM_GUARD) {
          <div class="tw-flex tw-gap-8 tw-flex-col tw-justify-center">
            <div class="tw-flex tw-flex-col tw-gap-4">
              <span class="title-description tw-text-gray-150 tw-text-center">{{
                'P2P_WIDGETS.PURCHASE.STEAM_GUARD_NEED' | translate
              }}</span>
              <span class="description tw-text-gray-400 tw-text-center"
                >{{ 'P2P_WIDGETS.PURCHASE.STEAM_GUARD_LINK' | translate }}
                <a
                  href="https://help.steampowered.com/ru/faqs/view/06b0-26e6-2cf8-254c"
                  class="tw-text-blue-500 tw-cursor-pointer tw-underline-none"
                  >{{ 'P2P_WIDGETS.PURCHASE.DETAILED_GUIDE' | translate }}</a
                >
              </span>
            </div>
            <div>
              <img
                src="/assets/img/p2p/steam-qr.png"
                class="tw-m-auto"
                alt=""
              />
            </div>
            <div class="tw-flex tw-justify-between tw-w-full tw-mt-2">
              <div
                class="tw-cursor-pointer"
                (click)="redirectToDownload('https://play.google.com/store/apps/details?id=com.valvesoftware.android.steam.community')"
              >
                <img
                  alt=""
                  src="/assets/img/p2p/google-play.png"
                />
              </div>
              <div
                class="tw-cursor-pointer"
                (click)="redirectToDownload('https://apps.apple.com/us/app/steam-mobile/id495369748')"
              >
                <img
                  src="/assets/img/p2p/appstore.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        }
        @if (userP2pPermissions.canTrade?.error === SteamErrors.INVALID_TRADELINK) {
          <app-p2p-setup-modal />
        }
      }
    }
  }
</div>
